.live-stats {

    .info {
        width: 100%;
        background-color: white;
        padding-bottom: 10px;
    }
}


