.score .score__head {
  position: relative;
  border-bottom: 2px solid #e7e7e7;
  text-align: center;
}

[class^="list-"] {
  list-style: none outside none;
}

.list-scores {
  padding: 19px 0 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;

  li {
    width: 40%;
  }

  li:nth-child(2) {
    width: 20%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .scores-data {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: rgb(70, 70, 70);
  }

  strong {
    font-size: 60px;
    line-height: 0.95;
    color: #707070;
    font-weight: 800;
  }

  small {
    font-size: 25px;
    line-height: 0.8;
    color: #f05d25;
    font-weight: bold;
    margin-bottom: 3px;
    margin-top: 3px;
  }
}

.arrow-up-orange {
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;

  border-bottom: 11px solid #f05d25;
}

.arrow-down-orange {
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;

  border-top: 11px solid #f05d25;
}

.table-score {
  &.table {
    text-transform: uppercase;
    text-align: center;
    width: 60%;
    font-size: 1.3em;
  }
}

.score-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  h2 {
    color: rgb(70, 70, 70);
    font-weight: 400;
    line-height: 1.2;
    margin: 10px 10px;
    width: 100%;
  }
}

.scoreboard-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .finished-scoreboard-stats {
    strong {
      color: #f05d25;
    }
  }

  .live-scoreboard-stats {
    display: flex;
    justify-content: space-evenly;

    .profiles {
      display: flex;

      .profile-image {
        width: 50px;
        height: 60px;
        max-width: 50px;
        max-height: 60px;
        min-width: 50px;
        min-height: 60px;
        margin: auto 7px;
      }

      .profile-data {
        display: flex;
        flex-direction: column;
        color: #707070;

        .role {
          margin: 0px;
          font-size: 10px;
          line-height: 1;
          color: #c0c0c0;
          text-transform: uppercase;
        }

        .name {
          font-size: 15px;
          font-weight: 700;
        }
      }
    }

    .canvas-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
