.video-list {

    &.error {
        font-size: xxx-large;
        text-align: center;
        height: 100%;
    }

    display: flex;
    flex-direction: column;
    background-color: white;

}
