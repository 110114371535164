.pbp-container {
  background-color: white;
  font-size: larger;

  .pbp-table-header {
    text-transform: uppercase;
  }

  .card-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.scoring-header {
        background-color: #02A4D3;
    }

    &:before {
      content: "\f107";
      color: #333;
      left: 0px;
      margin-right: 20px;
      font-family: "FontAwesome";
    }

    &.show {
      content: "\f106";
      font-family: "FontAwesome";
    }
  }

  .card h3 {
    font-size: 1.25rem;
    font-weight: 700;
  }

  .pitch-icon {
    border-radius: 50%;
    text-align: center;
    display: inline-flex;
    margin: auto 20px;
    font-weight: 700;
    font-size: 1.3em;
    width: 1.3em;
    height: 1.3em;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }
}
