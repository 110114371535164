.video-preview {
  width: 100px;
  min-width: 100px;
  height: 75px;
  text-align: center;
  position: relative;
  cursor: pointer;

  .image {
    width: 100%;
    height: 95%;
    background-size: cover;
    background-position: center;
    margin-bottom: 5px;
    border-radius: 15px;
  }

  .ico-play {
    box-sizing: border-box;
    position: absolute;
    display: block;
    top: 26px;
    left: 37px;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
    border: white 2px solid;
    border-radius: 20px;
  }

  .ico-play::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 0;
    height: 10px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: white 6px solid;
    top: 4px;
    left: 7px;
  }
}
