.canvas-container {
    
    display: block;
    background-size: contain;
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-color: white;

    &:empty {
        background-image: url('../../assets/heatmap.jpg');
        
        width: 102px;
        height: 136px;
    }
    
    &.radar {
        width: 102px;
        height: 136px;
        background-image: url('../../assets/BaseballPlate.png');
    }


}