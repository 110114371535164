nav.nav, .nav-tabs {

    .nav-link {

        background-color: #ebebeb;
        color: #464646;

        &.active {
            background-color: #464646;
            color: white;
        }
    }
}

.tab-pane {
    &.active {
        &.show {
            background-color: #464646;
        }
    }
}

canvas {
    padding: 0;
    margin: auto;
    display: block;
}

.live-plate-content, .last-play-content{
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}


    