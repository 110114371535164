.games-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: auto;

  .circle {
    display: block;
    background-color: #f80;
    border: 3px solid #fff;
    border-radius: 40px;

    /* offset-x | offset-y | blur-radius | spread-radius | color */
    box-shadow: 0 0 0 2px #888;
    height: 70px;
    width: 70px;
  }

  .section-a-game {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 94px;

    .game-name {
      width: 100%;
    }
  }
}

.game-container {
  cursor: pointer;

  &.selected {
    .circle {
      border: 3px solid #f80;
    }
  }
}
