.video-list-item {
  display: flex;
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
  align-items: center;

  &.selected {
    background-color: rgba(213, 112, 90, 0.3);
  }

  .thumbnail {
    width: 30%;
    height: auto;
  }

  .title {
    margin: auto 10px;
    font-weight: 500;
    font-size: 1.3em;
  }

  .video-list-item-details {
    display: flex;
    flex-direction: column;

    .details {
        margin: auto 10px;
    }

    .player-info-container {
      display: flex;

      .player-info {
        background-color: #d5705a;
        color: white;
        padding: 7px;
        border-radius: 23px;
        font-weight: 500;
        margin: 3px;
        font-size: x-small;
      }
    }
  }
}
