html,
body {
  margin: 0;
  padding: 0;
}

nav.nav, .nav-tabs {

    .nav-link {

        background-color: #ebebeb;
        color: #464646;

        &.active {
            background-color: #464646;
            color: white;
        }
    }
}

.tab-pane {
    &.active {
        &.show {
            background-color: #464646;
        }
    }
}