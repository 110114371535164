.video-highlights {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: white;

    .video-list-item {
        display: flex;
        margin: 10px 0;
        width: 100%;
        padding: 10px;
        cursor: pointer;

        &.selected {
            background-color: rgba(213, 112, 90, 0.3);
        }

        .thumbnail {
            width: 30%;
            height: auto;
        }

    }

}
