@import "@yaireo/tagify/src/tagify.scss";
:root {
    --tagify-dd-color-primary: #d5705a;
}

.video-tags {
    margin: auto 10px;

    .tagify {
        border: 0;
    }
}

.tags-look {
    display: flex;

    .tagify__dropdown__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .tagify__dropdown__item {
            background-color: antiquewhite;
        }
    }
}