.game-table {
  background-color: white;
}

.player-profile-header {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.player-profile-container {
  .tab-pane {
    text-align: center;

    h3 {
      font-size: 1.3em;
    }
    &.active {
      &.show {
        background-color: white;
      }
    }
  }
}

.profile-content {
  color: white;
  padding: 10px;

  h2 {
    font-size: 1.7em;
  }

  h3 {
    font-size: 1.3em;
  }
}

.boxes-canvases-container {
  display: flex;
  justify-content: center;
  margin: 15px;

  .box-canvas-wrapper {
    border: 1px solid #bbb;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}

.metric-charts-container {
  display: flex;
  padding: 10px;
  margin: 10px;
  flex-wrap: wrap;
  justify-content: center;
}
