.current-video-info {
  text-align: center;

  .h2 {
    color: #464646;
    font-weight: 400;
    line-height: 1.2;
    margin: 10px 10px;
  }

  .details {
    color: #464646;
    font-weight: 700;
    text-transform: uppercase;
  }

  .player-info {
    background-color: #d5705a;
    color: white;
    padding: 7px;
    border-radius: 23px;
    font-weight: 500;
    cursor: pointer;
  }

  .player-info-container {
    justify-content: space-evenly;
    display: flex;
    margin: 20px;
  }
}
