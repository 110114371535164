body {
  background: url('../../assets/background3.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.home-container {
  background-color: rgba(226,226,226,0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 1300px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-left: auto;
  margin-right: auto;
}

.scrollable {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0;
  margin: 0;
  overflow-x: hidden;

  &:empty {
    overflow-y: hidden;
  }
}

.scrollable-container {
  display: flex;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

.livestreams-player {
  width: 100%;
  display: flex;
}

.livestreams {
  width: 66%;
  margin-right: 0;
  padding-right: 0;
}

.player-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 33%;
  background-color: white;

  &:after {
    border-top: 1px solid grey;
    content: "";
    display: block;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.two-thirds {
    width: 66%;
    background-color: white;
}

.one-third {
    width: 33%;
}