.region {
  color: white;
  table {
    table-layout: fixed;
    height: 136px;

    td {
      padding: 3px;
      min-width: 11px;
      font-size: 0.6em;
    }

    tr:not(:first-child):not(:last-child)
      td:not(:first-child):not(:last-child) {
      border: 2px solid black;
    }
  }
}
